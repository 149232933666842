import ReactPlayer from 'react-player'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'

import { useTranslate } from '/machinery/I18n'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import { ImageCover } from '/features/buildingBlocks/Image'
import { ButtonIconBox } from '/features/buildingBlocks/Button'
import { LabelUnderline } from '/features/buildingBlocks/LabelUnderline'

import styles from './Video.css'

import iconPlay from '/images/icons/play.raw.svg'

const playerConfig = {
  vimeo: {
    playerOptions: {
      title: false
    }
  }
}

export function Video({ url, poster }) {
  const isMounted = useRenderOnMount()
  const [playing, setPlaying] = React.useState(false)
  const [hasInteracted, setHasInteracted] = React.useState(false)
  const videoRef = React.useRef(null)

  return (
    <div data-style-context='red' className={styles.component}>
      <div className={styles.container}>
        {!hasInteracted && (
          <>
            <PlayButton onClick={handlePlayPause} layoutClassName={styles.playButtonLayout} {...{ playing }} />

            {poster && (
              <ImageCover image={poster} aspectRatio={16 / 9} layoutClassName={styles.imageLayout} />
            )}
          </>
        )}

        <div className={styles.playerContainer} {...(!hasInteracted ? { inert: 'true' } : {})}>
          {isMounted && (
            <ReactPlayer
              width="100%"
              height="100%"
              onPlay={handlePlay}
              onPause={handlePause}
              ref={videoRef}
              config={playerConfig}
              controls
              // eslint-disable-next-line @kaliber/layout-class-name
              className={styles.player}
              {...{ url, playing }}
            />
          )}
        </div>
      </div>
    </div>
  )

  function handlePlayPause() {
    setPlaying(!playing)
  }

  function handlePlay() {
    setPlaying(true)
    setHasInteracted(true)
    pushToDataLayer({
      event: 'interaction',
      metadata: {
        interaction: {
          type: 'video',
          action: 'play',
          title: 'video'
        }
      }
    })
  }

  function handlePause() {
    setPlaying(false)
    pushToDataLayer({
      event: 'interaction',
      metadata: {
        interaction: {
          type: 'video',
          action: 'pause',
          title: 'video'
        }
      }
    })
  }
}

function PlayButton({ playing, onClick, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <button
      type='button'
      data-x='play-video'
      aria-label={playing ? __`pause` : __`play`}
      className={cx(styles.componentPlayButton, layoutClassName)}
      {...{ onClick }}
    >
      <ButtonIconBox layoutClassName={styles.iconLayout} icon={iconPlay} />
      <LabelUnderline label={__`play-video`} />
    </button>
  )
}
